import * as React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"

import { Link } from "gatsby"

const AboutUs = ({ data }) => {
    return (

        <Layout>
            <div className="site-wrapper-reveal border-bottom">

                <div className="featuted-product-wrap section-space--pt_120">
                    <div className="container">
                        <div className="row align-items-center featuted-product-one">
                            <div className="col-lg-6 col-md-6 order-lg-2 order-1">
                                <div className="product-thumbnail">
                                    <Img fluid={data.aboutUsImage.childImageSharp.fluid} alt="london vibra one phone booth"></Img>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 order-lg-1 order-2">
                                <div className="featured-product-contect">
                                    <h3 className="section-title">About Us</h3>
                                    <p className="section-space--mt_60">
                                    At Vibra, we are striving to conceptualize, design and deliver solutions that make workspaces your own. Our solutions vibrate with positive waves that help you focus, create and boost your productivity. We are here to provide you with solutions created to make sure you don’t lose your focus, serenity and joy.                            </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="featuted-product-wrap section-space--pt_120">
                    <div className="container">
                        <div className="row align-items-center featuted-product-one">
                            <div className="col-lg-6 col-md-6 order-lg-1 order-1">
                                <div className="product-thumbnail">
                                    <Img fluid={data.designImage.childImageSharp.fluid} alt="vibra telefonska govorilnica slovenija"></Img>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 order-lg-2 order-2">
                                <div className="featured-product-contect">
                                    <h3 className="section-title">Design principles</h3>
                                    <h6 className="section-space--mt_60">Modern Design</h6>
                                    <p>
                                        The Bauhaus design philosophy that modern furniture should complement architectural space, not compete with it. At Vibra, modern design is our guiding principle, and our passion has been shared by customers and design professionals worldwide.                                    </p>

                                    <h6>Instinctive and Human-Centered Design</h6>
                                    <p>
                                        We design products and experiences that address the needs of customers. Each of our products goes through a testing process in a real environment. We set a high bar for quality and usability, which is raised with every new product.                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="featuted-product-wrap section-space--pt_120">
                </div>

            </div>

        </Layout>

    )
}

export const query = graphql`
  query {
    designImage: file(relativePath: { eq: "about-us/design.png" }){
        childImageSharp {
            fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    aboutUsImage: file(relativePath: { eq: "about-us/aboutUs.jpeg" }){
        childImageSharp {
            fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
  }`



export default AboutUs
